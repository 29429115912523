import axios from "axios";
import { jwtDecode } from 'jwt-decode';
import { createAsyncThunk } from "@reduxjs/toolkit";
import setAuthToken from "../utilits/setAuthToken";
import { signInWithGoogle } from '../utilits/firebase';
const apiUrl = 'https://todozz-api.vercel.app/';
// const apiUrl = 'http://192.168.144.64:5000/';

// if(process.env.API_URL){
//     const apiUrl = process.env.API_URL;
// }

export const googleSignIn = createAsyncThunk(
    'auth/googleSignIn',
    async (_, { rejectWithValue }) => {
        try {
            const { token, profilePic } = await signInWithGoogle();
            if (!token) throw new Error('No token returned');
            const response = await axios.post(`${apiUrl}api/auth/google`, { token , profilePic });
            const token1 = response.data.token;
        localStorage.setItem('userJWTtoken',token1)
        const decode = jwtDecode(token1)
        setAuthToken(token1);
        return {...decode , profilePic}
        } catch (error) {
            console.error("Google Sign-In Error:", error);
            return rejectWithValue('Google Sign-In Failed'); // Handle rejection gracefully
        }
    }
);

export const loginStatus = createAsyncThunk('auth/loginStatus', async (loginData, thunkAPI)=>{
    try{
        const response = await axios.post(`${apiUrl}api/auth/login`,loginData);
        const token = response.data.token;
        console.log(token)
        const decode = jwtDecode(token)
        console.log(decode)
        localStorage.setItem('userJWTtoken',token)
        setAuthToken(token);
        return decode
    } catch(err){
        return thunkAPI.rejectWithValue(err.response.data.message || 'An error occurred')
    }
});
export const registerUser = createAsyncThunk('auth/registerUser', async (registerData,thunkAPI)=>{
    try{
        const response = await axios.post(`${apiUrl}api/auth/register`,registerData);
        const token = response.data.token;
        localStorage.setItem('userJWTtoken',token)
        const decode = jwtDecode(token)
        setAuthToken(token);
        return decode
    }catch(err){
        return thunkAPI.rejectWithValue(err.response.data.message || 'An error occured')
    }

})

