import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TopBar from "../components/TopBar";
import SideBar from "../components/SideBar";
import TaskSideBar from "../components/TaskSideBar";
const TodoListApp = () => {
    const [sideBarOpen, setSideBarOpen] = useState(true);
    const [taskSideBarOpen , setTaskSideBarOpen] = useState(false);
    const toggelTaskSideBar = () =>{
        setTaskSideBarOpen(!taskSideBarOpen);
    }
    const toggleSidebar = () => {
        setSideBarOpen(!sideBarOpen);
    };
    const [task, setTask] = useState(() => {
        const savedTask = localStorage.getItem('task');
        return savedTask ? JSON.parse(savedTask) : [];
    });
    const [taskDescription, setTaskdescription] = useState('');
    const [filter, setFilter] = useState('all');
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth)
    console.log(user);
    const addTAsk = () => {
        if (taskDescription.trim()) {

            const newTask = {
                id: Date.now(),
                description: taskDescription,
                createdAt: new Date(),
                status: false
            }
            setTask([...task, newTask]);
            console.log(filterTask)
            setTaskdescription('');

        }
    }
    useEffect(() => {
        localStorage.setItem('task', JSON.stringify(task))
    }, [task])
    const toggelTask = (id) => {
        setTask(task.map(tasks =>
            tasks.id === id ? { ...tasks, status: !tasks.status } : tasks
        ))
    }
    const delTask = (id) => {
        setTask(task.filter(tasks => tasks.id != id))
    };
    const filterTask = task.filter(tasks =>
        filter === 'complete' ? tasks.status :
            filter === 'incomplete' ? !tasks.status :
                true
    )

    const imTask = (id) => {

    }
    const updateTask = (id) => {

    }

    return (
        <div className="min-h-screen relative bg-secondary text-white">
            <div className='fixed top-0 left-0 right-0 z-50'>
                <TopBar toggleSidebar={toggleSidebar} />
            </div>
            <div className="flex">
                <div className={`fixed top-0 left-0 z-40 `}>
                    <SideBar isOpen={sideBarOpen} />
                </div>
                <div className={`transition-all duration-300 ease-in-out z-50 ${sideBarOpen ? 'md:ml-60' : 'ml-0'} ${taskSideBarOpen ? 'md:mr-80':'ml-0'}  mt-14 min-h-screen flex-col flex w-full justify-start items-center bg-secondary text-white `}>
                    <div className=" w-full  flex ">
                        <div className=" w-full flex flex-col items-center p-5 ">
                            <div className="border rounded-full  border-gray-400  px-3 py-3  md:w-4/5 flex  justify-between items-center mb-5">
                                <input className="bg-transparent w-full outline-0 mx-5" type="text" value={taskDescription} onChange={(e) => setTaskdescription(e.target.value)} placeholder="Enter a task" />
                                <button onClick={addTAsk}><img src="/Add.svg" alt="Add Icon" /></button>
                            </div>
                            <div className="border-t-0 md:border-t border-gray-400  w-full md:w-4/5  flex justify-center">
                                <button className={`border-b border-l border-t md:border-t-0 rounded-bl-xl border-gray-400 py-3 px-5 ${filter === 'all' ? 'active' : ''}`} onClick={() => setFilter('all')}>ALL</button>
                                <button className={`border-b border-l border-r   border-t md:border-t-0  py-3 px-5 border-gray-400 ${filter === 'complete' ? 'active' : ''}`} onClick={() => setFilter('complete')}>COMPLETE</button>
                                <button className={`border-b border-r  border-t md:border-t-0 rounded-br-xl  py-3 px-5 border-gray-400 ${filter === 'incomplete' ? 'active' : ''}`} onClick={() => setFilter('incomplete')}>INCOMPLETE</button>
                            </div>
                            <ul className="m-3 w-full md:w-4/5 ">
                                {filterTask.map(task =>
                                    <li className="border rounded-xl w-full border-gray-400 min-h-24 max-h-36 flex mt-3" key={task.id}>
                                        <label className="custom-checkbox w-8 mx-5  flex items-center  my-1">
                                            <input
                                                type="checkbox"
                                                checked={task.status}
                                                onChange={() => toggelTask(task.id)}
                                                className="hidden-checkbox"
                                            />
                                            <img
                                                src={task.status ? "/Check.svg" : "/UnCheck.svg"}
                                                alt="Checkbox"
                                                className="checkbox-image w-10  "
                                            />
                                        </label>
                                        <div className="border-l border-gray-400 m-1 w-64 md:w-full flex  relative">
                                            <span className="p-2  w-3/4 overflow-hidden" style={{ textDecoration: 'none' }}>{task.description}</span>
                                        <span className="p-1  text-sm text-gray-400 absolute bottom-0 right-0">Due Date: 29/05/2002</span>
                                        </div>
                                        <button className=" border-l p-2  border-gray-400 my-1 px-5" onClick={toggelTaskSideBar}> <img src="/edit.svg" width={32}  alt="" /> </button>
                                        <button className=" border-x p-2  border-gray-400 my-1 px-5" onClick={() => imTask(task.id)}> <img src="/Star.svg" width={32}  alt="" /> </button>
                                        <button className="p-2  w-12 mx-3 " onClick={() => delTask(task.id)}> <img src="/Delete.svg" width={32} alt="" /> </button>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>

                </div>
                <div className={`fixed top-0 right-0  `}>
                    <TaskSideBar isOpen={taskSideBarOpen} />
                </div>
            </div>
        </div>
    )
}
export default TodoListApp