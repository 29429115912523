import { useState } from "react";
import { useDispatch , useSelector } from "react-redux";
import { logout } from "../redux/reducers/authSlice";
const TopBar = ({toggleSidebar })=>{
    const dispatch =useDispatch();
    const { user } = useSelector((state) => state.auth)
    const [profileDropdown, setProfileDropdown] = useState(false);
    const logoutHandler = () => {

        dispatch(logout());
    };
    return(
        <div className=" w-full relative border-b border-gray-400 p-2 px-5 bg-secondary  flex justify-between items-center">
        <div className="cursor-pointer" onClick={toggleSidebar}>
           <img src="/manuIco.svg" alt="" />
        </div>
        <img src="/TODOZZ logo-png.svg" width={130} alt="" />
        <img className="rounded-full cursor-pointer" src={user.profilePic?user.profilePic:'/logo192.png'} onClick={() => setProfileDropdown(!profileDropdown)} width={38} alt="" />

        <div className={` ${profileDropdown ? '' : 'hidden'}  absolute top-10 right-2 border p-3  border-gray-400 mt-4 rounded-lg bg-primary w-60`}>
            <botton type='botton' className=" cursor-pointer flex justify-start items-center rounded-3xl p-3 hover:bg-secondary ">
                <img src="/user.svg" width={28} alt="" />
                <p className="pl-2">My Profile</p>
            </botton>
            <botton type='botton' onClick={logoutHandler} className=" cursor-pointer flex justify-start items-center rounded-3xl mt-3 p-3  hover:bg-secondary ">
                <img src="/logout.svg" width={28} alt="" />
                <p className="pl-2">Logout</p>
            </botton>
        </div>
    </div>
    )
}
export default TopBar;