import React from 'react';
import { Navigate ,Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  return (
    isAuthenticated?<Outlet/>:<Navigate to="/"/>
  );
};

export default PrivateRoute;
