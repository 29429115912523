import { createSlice } from "@reduxjs/toolkit";
import {loginStatus,registerUser,googleSignIn} from "../actions/authAction"

const authSlice = createSlice({
    name:'auth',
    initialState : {
        user:null,
        isAuthenticated:false,
        loading:false,
        err:null,
        profilePic: null
    },
    reducers:{
        logout:(state)=>{
            localStorage.removeItem('userJWTtoken');
            state.user=null;
            state.isAuthenticated=false;
        },
        setUser:(state,action)=>{
            state.loading=false;
            state.isAuthenticated=true;
            state.err=null;
            state.user = action.payload;
            state.profilePic = action.payload.profilePic;
        }
    },
    extraReducers: (builder) =>{
        builder
            .addCase(loginStatus.pending,(state)=>{
                state.loading=true;
            })
            .addCase(loginStatus.fulfilled,(state,action)=>{
                state.isAuthenticated=true;
                state.loading=false;
                state.err=null;
                state.user = action.payload;
            })
            .addCase(loginStatus.rejected,(state,action)=>{
                state.loading=false;
                state.isAuthenticated=false;
                state.err=action.payload;
            })
            .addCase(registerUser.pending,(state)=>{
                state.loading=true;
            })
            .addCase(registerUser.fulfilled,(state,action)=>{
                state.loading=false;
                state.isAuthenticated=true;
                state.err=null;
                state.user = action.payload;
            })
            .addCase(registerUser.rejected,(state,action)=>{
                state.loading=false;
                state.err=action.payload;
            })
            .addCase(googleSignIn.pending,(state)=>{
                state.loading=true;
            })
            .addCase(googleSignIn.fulfilled,(state,action)=>{
                state.loading=false;
                state.isAuthenticated=true;
                state.err=null;
                state.user = action.payload;
                state.profilePic = action.payload.profilePic;
            })
            .addCase(googleSignIn.rejected,(state,action)=>{
                state.loading=false;
                state.err=action.payload;
            })
    }
})
export const{logout,setUser} = authSlice.actions;
export default authSlice.reducer;