import { BrowserRouter as Router, Route, Routes  } from "react-router-dom"
import { jwtDecode } from 'jwt-decode';
import LoginTodozz from "./pages/LoginTodoz";
import TodoListApp from "./pages/Todo";
import AiTodo from "./pages/AiTodo";
import PrivateRoute from "./redux/PrivateRoute"
import{setUser} from "./redux/reducers/authSlice"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import ImportentTodo from "./pages/ImportentTodo";
import PlannedTodo from "./pages/PlannedTodo";
const App = () => {
    const dispatch =useDispatch()
    useEffect(()=>{
        const token = localStorage.getItem('userJWTtoken');
        if(token){
            const decodeToken =jwtDecode(token)
            dispatch(setUser(decodeToken))
        }
    },[dispatch])
    return(
       <Router>
            <Routes>
                <Route path="/" element={<LoginTodozz/>}/>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/home" element={<TodoListApp/>}/>
                        <Route path="/ai-todo" element={<AiTodo/>}/>
                        <Route path="/importent-todo" element={<ImportentTodo/>}/>
                        <Route path="/planned-todo" element={<PlannedTodo/>}/>
                    </Route>               
            </Routes>
       </Router>
    )
}
export default App