import { NavLink } from "react-router-dom";
const SideBar = ({isOpen}) =>{
    return(
        <div className={`transform transition-transform duration-300 ease-in-out  ${isOpen ? 'translate-x-0' : '-translate-x-full'} w-60 mt-14  text-white   w-full h-screen max-h-dvh bg-primary p-3 `}>
                    <NavLink
                    to="/home"
                    className={({isActive}) => isActive?'cursor-pointer flex justify-start items-center rounded-xl p-3 bg-secondary ':'cursor-pointer flex justify-start items-center rounded-xl p-3 hover:bg-secondary'}
                    >
                        <img src="/TodayTask.svg" width={25} alt="" />
                        <p className="pl-2">Today Tasks</p>
                    </NavLink>
                    <NavLink
                    to="/ai-todo"
                    className={({isActive}) => isActive?'cursor-pointer flex justify-start items-center rounded-xl p-3 bg-secondary ':'cursor-pointer flex justify-start items-center rounded-xl p-3 hover:bg-secondary'}
                    >
                        <img src="/AiGen.svg" width={25} alt="" />
                        <p className="pl-2">AI Tasks Generator</p>
                    </NavLink>
                    <NavLink
                    to="/importent-todo"
                    className={({isActive}) => isActive?'cursor-pointer flex justify-start items-center rounded-xl p-3 bg-secondary ':'cursor-pointer flex justify-start items-center rounded-xl p-3 hover:bg-secondary'}
                    >
                        <img src="/Star.svg" width={25} alt="" />
                        <p className="pl-2">Importent</p>
                    </NavLink>
                    <NavLink
                    to="/planned-todo"
                    className={({isActive}) => isActive?'cursor-pointer flex justify-start items-center rounded-xl p-3 bg-secondary ':'cursor-pointer flex justify-start items-center rounded-xl p-3 hover:bg-secondary'}
                    >
                        <img src="/Plan.svg" width={25} alt="" />
                        <p className="pl-2">Planned</p>
                    </NavLink>
                </div>
    );
}
export default SideBar;