import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginStatus, registerUser, googleSignIn } from "../redux/actions/authAction";
import { signInWithGoogle } from "../redux/utilits/firebase";
const LoginTodozz = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [localErr, setLoaclerr] = useState('');
    const { isAuthenticated, loading, err } = useSelector((state) => state.auth)
    const [filter, setFilter] = useState('login');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginFormHandel = (e) => {
        e.preventDefault();
        setLoaclerr('')
        if (email === '') {
            setLoaclerr('Please enter email')
        } else if (password === '') {
            setLoaclerr('Please enter password')
        }
        else {
            dispatch(loginStatus({ email, password }));
        }
    };
    const registerFormHandel = (e) => {
        e.preventDefault();
        setLoaclerr('')
        if (email === '') {
            setLoaclerr('Please enter email')
        } else if (password === '') {
            setLoaclerr('Please enter password')
        }
        else {
            dispatch(registerUser({ name, email, password }));
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/home')
        }
    }, [isAuthenticated, navigate])
    const Spinner = () => {
        return (
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
        );
    };
    const handleGoogleSignIn = async () => {
        dispatch(googleSignIn());

    };
    return (
        <div className="min-h-screen   flex justify-center bg-secondary text-white">
            <div className="w-1/2 flex flex-col items-center p-5 ">
                <div className="text-center mb-5 w-screen md:w-72 flex flex-col justify-center items-center ">
                    <h1 className="text-2xl font-bold mb-2">Welcom to </h1>
                    <img className="w-60 mb-1" src="TODOZZ logo-png.svg" alt="" />
                    <span>Organize, Prioritize, Achieve More!</span>
                </div>
                <div className="border-t border-gray-400 w-72 md:w-4/5  flex justify-center">
                    <button className={`border-b border-l rounded-bl-xl border-gray-400 py-3 px-5 ${filter === 'login' ? 'active' : ''}`} onClick={() => setFilter('login')}>Login</button>
                    <button className={`border-b border-r rounded-br-xl  py-3 px-5 border-gray-400 ${filter === 'register' ? 'active' : ''}`} onClick={() => setFilter('register')}>Register</button>
                </div>
                {filter === 'login' && (<div>
                    <form className="border  border-gray-400 min-w-80 rounded-xl py-5 px-3 md:p-10 flex flex-col  justify-between items-center mt-10" onSubmit={loginFormHandel}>
                        <div className="form-group">
                            <label htmlFor="email">Email :</label><br />
                            <div className="border rounded-full  border-gray-400  px-3 py-1  mt-3 flex  justify-between items-center">
                                <img className="border-r border-gray-400 pr-3 py-2" src="/user.svg" alt="" />
                                <input className="bg-transparent w-full outline-0 mx-5 ml-2" type="email" onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group mt-4">
                            <label htmlFor="Password">Password :</label><br />
                            <div className=" border rounded-full  border-gray-400  px-3 py-1  mt-3 flex  justify-between items-center">
                                <img className="border-r border-gray-400 pr-3 py-2" src="/password.svg" alt="" />
                                <input className="bg-transparent w-full outline-0 mx-5 ml-2" type="password" onChange={(e) => setPassword(e.target.value)} />
                            </div>
                        </div>

                        {loading ? (<div className="spinner">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                        </div>) : (<><button className="bg-white p-4 rounded-full text-secondary w-full my-5" type="submit">
                            Submit
                        </button> 
                    <div className="flex  justify-center items-center w-full">
                    <span className="w-36 border-t  border-gray-400"></span>
                    <p className="mx-2">OR</p>
                    <span className="w-36 border-t  border-gray-400"></span>
                </div>
            <button className="bg-white p-4 rounded-full text-secondary w-full mt-5 flex  justify-center items-center"  type="button" onClick={handleGoogleSignIn}>
               <img src="/Google.svg" className="mr-3" alt="" /> Continue with Google
            </button></>)}
                        {localErr && <p style={{ color: 'red' }} className="mt-2 ">{localErr}</p>}
                        {err && <p style={{ color: 'red' }} className="mt-2 ">{err}</p>}

                    </form>
                </div>)}
                {filter === 'register' && (<div>
                    <form className="border  border-gray-400 min-w-80 rounded-xl py-5 px-3 md:p-10 flex flex-col  justify-between items-center mt-10" onSubmit={registerFormHandel}>
                        <div className="form-group">
                            <label htmlFor="name">Name :</label><br />
                            <div className="border rounded-full  border-gray-400  px-3 py-1 mt-3 flex  justify-between items-center">
                                <img className="border-r border-gray-400 pr-3 py-2" src="/user.svg" alt="" />
                                <input className="bg-transparent w-full outline-0 mx-5 ml-2" type="text" onChange={(e) => setName(e.target.value)} required />
                            </div>
                        </div>
                        <div className="form-group mt-4">
                            <label htmlFor="email">Email :</label><br />
                            <div className="border rounded-full  border-gray-400  px-3 py-1 mt-3 flex  justify-between items-center">
                                <img className="border-r border-gray-400 pr-3 py-2" src="/email.svg" alt="" />
                                <input className="bg-transparent w-full outline-0 mx-5 ml-2" type="email" onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group mt-4">
                            <label htmlFor="Password">Create Password :</label><br />
                            <div className="border rounded-full  border-gray-400  px-3 py-1 mt-3 flex  justify-between items-center">
                                <img className="border-r border-gray-400 pr-3 py-2" src="/password.svg" alt="" />
                                <input className="bg-transparent w-full outline-0 mx-5 ml-2" type="password" onChange={(e) => setPassword(e.target.value)} />
                            </div>
                        </div>
                        {loading ? (<div className="spinner">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                        </div>) : (<><button className="bg-white p-4 rounded-full text-secondary w-full my-5" type="submit">
                            Submit
                        </button> 
                    <div className="flex  justify-center items-center w-full">
                    <span className="w-36 border-t  border-gray-400"></span>
                    <p className="mx-2">OR</p>
                    <span className="w-36 border-t  border-gray-400"></span>
                </div>
            <button className="bg-white p-4 rounded-full text-secondary w-full mt-5 flex  justify-center items-center"  type="button" onClick={handleGoogleSignIn}>
               <img src="/Google.svg" className="mr-3" alt="" /> Continue with Google
            </button></>)}
                        {localErr && <p style={{ color: 'red' }} className="mt-2 ">{localErr}</p>}
                        {err && <p style={{ color: 'red' }}>{err}</p>}
                    </form>
                </div>)}
            </div>
        </div>
    )
}
export default LoginTodozz