import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TopBar from "../components/TopBar";
import SideBar from "../components/SideBar";
const PlannedTodo = () => {
    const [sideBarOpen, setSideBarOpen] = useState(true);

    const toggleSidebar = () => {
        setSideBarOpen(!sideBarOpen);
    };
  
    return (
        <div className="min-h-screen relative bg-secondary text-white">
            <div className='fixed top-0 left-0 right-0 z-50'>
                <TopBar toggleSidebar={toggleSidebar} />
            </div>
            <div className="flex">
                <div className={`fixed top-0 left-0 z-40 `}>
                    <SideBar isOpen={sideBarOpen} />
                </div>
                <div className={`${sideBarOpen?'ml-80':''}  mt-14 min-h-screen flex-col flex w-full justify-start items-center bg-secondary text-white  `}>
                  

                </div>
            </div>
        </div>
    )
}
export default PlannedTodo