// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAi_Mu21ff0hQHidXeYKATmahN6CAWSjc0",
  authDomain: "todozz-437af.firebaseapp.com",
  projectId: "todozz-437af",
  storageBucket: "todozz-437af.appspot.com",
  messagingSenderId: "79021349222",
  appId: "1:79021349222:web:7eff065723e30042ed90a7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const profilePic = user.photoURL;
    const token = await user.getIdToken();  // Get the Firebase token
    console.log('User Profile Pic URL:', profilePic);
    return { user, token ,profilePic };
  } catch (error) {
    console.error(error);
  }
};
